/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import t from "../Collection.js";
import { castForReferenceSetter as s, referenceSetter as o } from "../collectionUtils.js";
import "../has.js";
import "../Logger.js";
import { property as r } from "../accessorSupport/decorators/property.js";
import "../RandomLCG.js";
import { subclass as i } from "../accessorSupport/decorators/subclass.js";
let n = class extends t {
  constructor(e) {
    super(e), this.addHandles([this.on("before-add", e => {
      null == e.item && e.preventDefault();
    }), this.on("after-add", e => this._own(e.item)), this.on("after-remove", e => this._release(e.item))]);
  }
  get owner() {
    return this._get("owner");
  }
  set owner(e) {
    e !== this._get("owner") && (this._releaseAll(), this._set("owner", e), this._ownAll());
  }
  _ownAll() {
    for (const e of this.items) this._own(e);
  }
  _releaseAll() {
    for (const e of this.items) this._release(e);
  }
  _createNewInstance(e) {
    return this.itemType ? new (t.ofType(this.itemType.Type))(e) : new t(e);
  }
};
function l(e, t) {
  return {
    type: e,
    cast: s,
    set(s) {
      const r = o(s, this._get(t), e);
      r.owner = this, this._set(t, r);
    }
  };
}
e([r()], n.prototype, "owner", null), n = e([i("esri.core.support.OwningCollection")], n);
export { n as OwningCollection, l as owningCollectionProperty };